<template>
  <div class="input-group">
    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">{{ sourceList[sourceIndex].name }}
    </button>
    <ul class="dropdown-menu">
      <li v-for="(item, index) in sourceList" v-bind:key="'sourceList' + index">
        <a class="dropdown-item" href="#" @click="selectSource(index)">{{ item.name }}</a>
      </li>
    </ul>
    <input v-focus type="search" v-model="keyword" class="form-control" @keydown.enter="childSearch()">
    <button class="btn btn-outline-secondary" type="button" @click="childSearch()">在此一搜</button>
  </div>
</template>

<script type="text/javascript">
// import { jsonp } from '@/common/util/request'
import getByField from '@/common/util/dataUtils'

export default {
  name: 'Search',
  components: {},
  props: {
    sourceList: {
      type: Array
    },
    search: {
      type: Function,
      default: null
    },
    inKeyword: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      sourceIndex: 0,
      keyword: ''
    }
  },
  directives: {
    focus: {
      // 指令的定义
      inserted: function (el) {
        el.focus()
      }
    }
  },
  watch: {
    inKeyword: function (newV, oldV) {
      this.keyword = this.inKeyword
    }
  },
  methods: {
    selectSource: function (index) {
      this.sourceIndex = index
    },
    childSearch: function () {
      if (this.search && this.keyword) {
        this.search(this.sourceIndex, this.keyword)
      }
    },
    // &event是实参，表示event对象
    getSearchData: function () {
      this.$jsonp('https://sug.so.360.cn/suggest?word=' + this.keyword + '&encodein=utf-8&encodeout=utf-8').then(res => {
        this.isShowSelect = true
        this.myData = getByField(res.result, 'word')
      })
    },
    get: function (ev) {
      // 如果按得键是上或者下，就不进行ajax
      if (ev.keyCode === 38 || ev.keyCode === 40 || this.keyword === '') {
        return
      }
      this.getSearchData()
    },
    clearInput: function () {
      this.keyword = ''
      this.getSearchData()
    },
    selectDown: function () {
      this.now++
      if (this.now === this.myData.length) {
        this.now = 0
      }
      this.keyword = this.myData[this.now]
    },
    selectUp: function () {
      this.now--
      if (this.now === -1) {
        this.now = this.myData.length - 1
      }
      this.keyword = this.myData[this.now]
    },

    selectHover: function (index) {
      this.now = index
    },
    selectClick: function (index) {
      this.keyword = this.myData[index]
      this.isShowSelect = false
      this.search()
    },
    selectChange: function () {
      setTimeout(() => {
        this.isShowSelect = false
      }, 100)
    },
    getIndex: function (index) {
      this.searchIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
input:focus {
  box-shadow: none;
  border-color: #686868;
}
</style>
