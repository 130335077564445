<template>
  <div class="card"  style="width: 11rem;">
    <img :src="imgUrl" class="card-img-top px-5 py-3" style="height: 6.9rem;background-color: #DBDFE7">
    <div class="card-body p-2">
      <p class="card-title text-nowrap" style="font-size: small">{{ name }}</p>
      <p class="card-text text-nowrap" style="font-size: smaller; color: #5e687d">{{ desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    name: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
