<template>
  <div class="container">
    <div class="row py-5">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <Search :source-list="sourceList" :inKeyword="keyword" :search="search"></Search>
      </div>
      <div class="col-md-3"></div>
    </div>
    <div v-if="isRecommend">
      <Recommend :click-recommend="clickRecommend"></Recommend>
    </div>
    <div v-else>
      <div class="px-1" style="font-size: smaller;">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">首页</a></li>
            <li class="breadcrumb-item"><a href="#" @click="backToRecommend">小编推荐</a></li>
            <li class="breadcrumb-item active" aria-current="page">"{{ this.keyword }}" 的搜索结果</li>
          </ol>
        </nav>
      </div>
      <div class="my-1 px-1" style="font-size: smaller;">共有{{ total }}款软件</div>
      <div class="row">
        <div>
          <SoftItem v-for="(item,index) in list" :key="'list' + index" :name="item.name" :bit="item.bit"
                    :desc="item.desc"
                    :img-url="item.imgUrl" :file-size="item.fileSize" :version="item.version"
                    :d-url="item.dUrl"></SoftItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/components/search/Search'
import SoftItem from '@/components/software/SoftItem'
import Recommend from '@/views/soft/Recommend'
import { jsonp } from 'vue-jsonp'

export default {
  name: 'Soft',
  components: {
    Search,
    SoftItem,
    Recommend
  },
  data () {
    return {
      sourceList: [{
        name: '腾讯',
        searchSrc: 'https://s.pcmgr.qq.com/tapi/web/searchcgi.php?type=search&callback=searchCallback&page=1&pernum=30&more=0&keyword='
      }, {
        name: '360',
        searchSrc: `https://bapi.safe.360.cn/soft/search?page=1&_=${(new Date()).getTime()}&keyword=`
      }],
      list: [],
      total: 0,
      isRecommend: true,
      keyword: ''
    }
  },
  methods: {
    backToRecommend () {
      this.isRecommend = true
    },
    clickRecommend (name) {
      console.log(name)
      this.search(0, name)
      this.keyword = name
    },
    search (index, keyword) {
      this.isRecommend = false
      this.keyword = keyword
      const that = this
      console.log(1)
      jsonp(this.sourceList[index].searchSrc + keyword, {}).then((res) => {
        if (index === 1) {
          that.change360ToList(res.data)
        } else {
          that.changeTxToList(res)
        }
      })
    },
    changeTxToList (data) {
      this.total = data.info.total
      this.list = data.list.map(item => {
        const infoJson = this.$x2js.xml2js(item.xmlInfo)
        const target = {}
        const soft = infoJson.soft
        target.imgUrl = `//pc3.gtimg.com/softmgr/logo/48/${soft.logo48.toLocaleLowerCase()}`
        target.name = soft.displayname
        target.dUrl = soft.https
        target.desc = soft.feature
        target.bit = soft._osbit
        target.fileSize = soft.filesize
        target.version = soft.versionname
        return target
      })
    },
    change360ToList (data) {
      this.total = data.total
      this.list = data.list.map(item => {
        const target = {}
        target.imgUrl = item.logo
        target.name = item.softname
        target.dUrl = item.soft_download
        target.desc = item.desc
        target.version = item.version
        target.fileSize = item.size
        return target
      })
    }
  }
}
</script>

<style scoped>

</style>
