<template>
  <div class="card py-2 border-0 border-bottom">
    <div class="row g-0">
      <div class="col-md-2 align-self-center text-center my-2">
        <img :src="imgUrl" class="img-fluid rounded-start mx-1" style="width: 48px;height: 48px;">
      </div>
      <div class="col-md-4">
        <div class="card-body">
          <small class="card-title">{{ name }}
            <span class="mx-1 badge rounded-pill bg-primary">{{ bitStr }}</span>
          </small>
          <p class="card-text overflow-auto pt-1" style="font-size: smaller; color: #5e687d">{{ desc }}</p>
        </div>
      </div>
      <div class="col-md-4 align-self-center">
        <div class="card-body py-0 px-3 float-start">
          <p class="card-text"><small class="text-muted text-nowrap">{{ version }}</small></p>
        </div>
        <div class="card-body py-0 px-3 float-end">
          <p class="card-text"><small class="text-muted text-nowrap">{{ fileSizeStr }}</small></p>
        </div>
      </div>
      <div class="col-md-2 text-center align-self-center my-2">
        <a :href="dUrl" class="btn btn-outline-success btn-sm text-nowrap">立即下载</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SoftItem',
  data: function () {
    return {}
  },
  computed: {
    bitStr: function () {
      if (this.bit === '2') {
        return '64位'
      } else if (this.bit === '1') {
        return '32位'
      } else {
        return ''
      }
    },
    fileSizeStr: function () {
      if (isNaN(this.fileSize)) return this.fileSize
      let i = 0
      let fileSizeNum = parseInt(this.fileSize)
      while (Math.abs(fileSizeNum) >= 1024) {
        fileSizeNum = fileSizeNum / 1024
        i++
        if (i === 4) break
      }
      const units = ['B', 'KB', 'MB', 'GB', 'TB']
      const fileSizeStr = Math.round(fileSizeNum, 2)
      return fileSizeStr + ' ' + units[i]
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    },
    dUrl: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    bit: {
      type: String,
      default: ''
    },
    fileSize: {
      type: String,
      default: ''
    },
    version: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
