<template>
  <div>
    <div class="px-2" style="font-size: smaller;">
      <img :src="require('@/assets/icon_software_my.png')" style="height: 19px;" alt="软件下载">
      <span class="px-2">小编推荐</span>
    </div>
    <div>
      <div class="clearfix w-auto mx-auto">
        <div v-for="(item,index) in recommendList" :key="'recommendList' + index" class="float-start w-auto p-2"
             @click="childClickRecommend(item.name)">
          <card :name="item.name" :desc="item.desc" :img-url="item.imgUrl"></card>
        </div>
      </div>
    </div>
    <div class="px-2" style="font-size: smaller;">
      <img :src="require('@/assets/icon_software_my.png')" style="height: 19px;" alt="软件下载">
      <span class="px-2">实用工具</span>
    </div>
    <div>
      <div class="clearfix w-auto mx-auto">
        <div v-for="(item,index) in toolList" :key="'toolList' + index" class="float-start w-auto p-2">
          <a :href="item.url" target="_blank">
            <card :name="item.name" :desc="item.desc" :img-url="item.imgUrl"></card>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/card/Card'

export default {
  name: 'Recommend',
  components: {
    Card
  },
  data () {
    return {
      recommendList: [
        {
          name: '谷歌浏览器',
          desc: '一个干净的浏览器',
          imgUrl: '//pc3.gtimg.com/softmgr/logo/48/302_48_1450768888.png'
        },
        {
          name: '腾讯桌面整理',
          desc: '桌面整理',
          imgUrl: '//pc3.gtimg.com/softmgr/logo/48/23125_48_1479364559.png'
        },
        {
          name: '温度监测',
          desc: '电脑温度监测',
          imgUrl: '//pc3.gtimg.com/softmgr/logo/48/22144_48_1446087333.png'
        }, {
          name: 'QQ影音',
          desc: '本地视频播放器',
          imgUrl: '//pc3.gtimg.com/softmgr/logo/48/8_48_1560156252.png'
        },
        {
          name: '7-Zip',
          desc: '压缩、解压缩工具',
          imgUrl: '//pc3.gtimg.com/softmgr/logo/48/2685_48_1456478802.png'
        },
        {
          name: 'Typora',
          desc: 'Markdown（md）编辑器',
          imgUrl: '//pc3.gtimg.com/softmgr/logo/48/24041_48_1515574048.png'
        }],
      toolList: [
        {
          name: 'MySQL Workbench',
          desc: 'Oracle官方数据库连接工具',
          imgUrl: 'https://s.cn.bing.net/th?id=ODLS.cc5e2bd9-8fee-480e-a4b1-12fa6c66174e',
          url: 'https://dev.mysql.com/downloads/workbench/'
        }, {
          name: 'MobaXterm_Personal',
          desc: 'SSH连接工具',
          imgUrl: 'https://mobaxterm.mobatek.net/img/moba/xterm_logo.png',
          url: 'https://mobaxterm.mobatek.net/download-home-edition.html'
        }
      ]
    }
  },
  props: {
    clickRecommend: {
      type: Function,
      default: null
    }
  },
  methods: {
    childClickRecommend: function (name) {
      this.clickRecommend(name)
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  outline: none;
  color: #000;
}
</style>
